import Vue from "vue";
import { API } from "../../services/API"
import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {
  return {
    dados       : [],
    paginas     : 0,
    count_dados : null,

  }
}

var vm_store_Usuarios = new Vue({

  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    async UsuariosGet(p_params) {
      const resposta = await API.get("usuario", {
                                                  params: {
                                                      ...p_params
                                                  },
                                                  }
                                      );
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          if (p_params && (p_params.cod_usuario || p_params.cod_funcao)) {
            //console.log("usuarios -- 2 ", resposta.data.result);
            //this.usuario_selecionado = resposta.data.result;
            return resposta.data.result
          }
          else {
            this.dados   = resposta.data.result.rows;
            this.paginas = resposta.data.result.paginas;
          }
        }
        else
          this.dados = [];
      }
    },

    async UsuariosParceirosGet() {
        const resposta = await API.get("usuario_parceiro", {
                                                    params: {
                                                      cod_funcao: 8
                                                    },
                                                  }
                                        );
        if (resposta) {
          if (resposta.data.result != 'Não possui dados') {
            return resposta.data.result.rows;
          }
          else {
            return resposta.data.result;
          }
        }
        else
          return [];
    }
  
  }
    
})

export default vm_store_Usuarios
