import Vue from "vue";
import { API } from "../../services/API"
import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {
  return {
    dados                 : [],
    acao                  : "C", // E=Edicao, I=Inclusão, C=Consulta
    proposta_selecionado  : {}, 

    edicao_dadosbasicos   : {},
    edicao_documentos     : {},
    edicao_contrato       : {},
    edicao_transferencia  : {},
    edicao_renegociacao   : {},
    edicao_rescisao       : {},
    edicao_escritura      : {},
    edicao_mensagens      : {},

    dialog_cadastro       : false,
    dialogFiltroAvancado  : false,

    dialogEnderecosCadastro       : false,
    dialogDocumentacaoCadastro    : false,
    dialogContatosCadastro        : false,
    dialogDadosBancariosCadastro  : false,
    dialogSociosCadastro          : false,

    dialogContratoCriacao             : false,
    dialogTransferenciaCriacao        : false,
    dialogRenegociacaoCriacao         : false,
    dialogEscrituraCriacao            : false,
    dialogAutorizacaoEscrituraCriacao : false,
    dialogRescisaoCriacao             : false,
    dialogMensagensCriacao            : false,

    docDocumentos             : 0,
    docContrato               : 0,
    docFinanceiro             : 0,
    docTransferencia          : 0,
    docRenegociacao           : 0,
    docRescisao               : 0,
    docAutorizacaoEscritura   : 0,
    docEscritura              : 0,
    docMensagens              : 0,
    docPrivilegios            : 0,

    filtro: {
      // Filtro na Tela de Consulta --------
      cliente_nome        : null,
      cod_empreendimento  : null,
      empreendimento      : null,
      data_inicial        : null,
      data_final          : null,

      quadra              : null,
      lote                : null,
      num_proposta        : null,
      venc_inicial        : null,
      venc_final          : null,

      // Filtro Avancado --------------------
      situacao: null,
      proprietario: null,
      contrato: null,
      num_cobranca: null,
      tipo_parcela: null,
      parcela: null,
      tipo_impressao: null,
      bloqueados: null,
      reajustados: null,
      proposta_inicial: null,
      proposta_final: null,
      data_contrato: null,
      pagamento_inicial: null,
      pagamento_final: null,
      impressao_inicial: null,
      impressao_final: null,
    },

    abas : [
      ['mdi-view-dashboard-outline', 'Dados Básicos', '0'],
      ['mdi-file-document-outline', 'Documentos', '0'],
      ['1', 'separador', '0'],
      ['mdi-cash-multiple','Parcelas', '2'],
      ['2', 'separador', '0'],
      ['mdi-text-box-outline','Contrato', 'OK'],
      ['mdi-text-box-plus-outline','Transferência', '0'],
      ['mdi-text-box-multiple-outline','Renogociação', '0'],
      ['mdi-text-box-minus-outline','Rescisão', '0'],
      ['mdi-text-account','Autorização de Escritura', 'OK'],
      ['mdi-script-text-play-outline','Escritura', '1'],
      ['3', 'separador', '0'],
      ['mdi-message-reply-text-outline','Mensagens', '0'],
      ['mdi-message-reply-text','Privilegios', '0'],
    ]

  }
}

var vm_store_Proposta = new Vue({

  data () {
    return initialState();
  },

  methods: {
    menuAbas(){
      var abas_aux = []
      this.proposta_selecionado.menu_abas.forEach(item => {
        var array = [item.icon, item.text, item.quant];
        abas_aux.push(array);
      })

      return abas_aux 
    },

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    atualiza_abas() {
      this.abas = [
        ['mdi-view-dashboard-outline', 'Dados Básicos', 0],
        ['mdi-file-document-outline', 'Documentos', this.docDocumentos],
        ['1', 'separador', 0],
        ['mdi-cash-multiple','Parcelas', 0],
        ['2', 'separador', 0],
        ['mdi-text-box-outline','Contrato', this.docContrato],
        ['mdi-text-box-plus-outline','Transferência', this.docTransferencia],
        ['mdi-text-box-multiple-outline','Renogociação', this.docRenegociacao],
        ['mdi-text-box-minus-outline','Rescisão', this.docRescisao],
        ['mdi-text-account','Autorização de Escritura', this.docAutorizacaoEscritura],
        ['mdi-script-text-play-outline','Escritura', this.docEscritura],
        ['3', 'separador', 0],
        ['mdi-message-reply-text-outline','Mensagens', this.docMensagens],
        ['mdi-message-reply-text','Privilégios', this.docPrivilegios],
      ]
    },

    async PropostaEmails(p_params) {
      const resposta = await API.get("proposta_emails", {
                                                  params: {
                                                      cod_opor: p_params
                                                  },
                                                  }
                                      );
      if (resposta)
          return resposta.data.result.data
      else 
        return [];
    },

      async PropostaGet(p_params) {
        // console.log("p_params", p_params)
        // var lo_Params;
        // if (p_codUsuario)
        //     lo_Params = {cod_usuario : p_codUsuario}
        // else if (p_filtro)
        //     lo_Params = {filtro : p_filtro}
        // else
        //     lo_Params = {}

        const resposta = await API.get("venda_individual", {params: {...p_params},});
        if (resposta) {
          this.dados = [];
          if (resposta.data.result != 'Não possui dados') {
            if (p_params && p_params.cod_empreendvenda) {
              return resposta.data.result
            }
            else {
                this.dados   = resposta.data.result.rows;
                this.paginas = resposta.data.result.paginas;
            }
          }
        }
      },
  
    //   async PessoaPost(body) {
    //     const resp = await API.post(`/pessoa`, JSON.stringify(body));
    //     const { data } = resp;
    //     return data;
    //   },
        
    //   async PessoaPut(body) {
    //     const resp = await API.put(`/pessoa/${body.cod_pessoa}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   async PessoaDelete(body) {
    //     const resp = await API.delete(`/pessoa/${body.cod_pessoa}`)
    //     const { data } = resp;
    //     if (resp.status == 200) 
    //       return data.result;
    //     else
    //       return data;
    //   }

    // Escritura
        async EscrituraPut(body) {
        const resp = await API.put(`/escritura/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },
    // Escritura
        async EscrituraDelete(body) {
        //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
        const resp = await API.put(`/escritura_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

       // Autorização de escritura
       async AutorizacaoPut(body) {
        const resp = await API.put(`/autorizacao/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

      // Escritura
      async AutorizacaoDelete(body) {
        //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
        var resp = await API.put(`/autorizacao_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

      // Rescisão
      async RescisaoDelete(body) {
        //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
        var resp = await API.put(`/rescisao_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

      // Rescrisão 
      async RescisaoPut(body) {
        const resp = await API.put(`/rescisao/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },
      
      async ModeloPost(body) {
        const resp = await API.post(`/editor_documento`, JSON.stringify(body));
        const { data } = resp;
        return data;
      },

      async ModeloDelete(body) {
        const resp = await API.delete(`/editor_documento/${body.cod_editor_documento}`)
        const { data } = resp;
        if (resp.status == 200) 
          return data.result;
        else
          return data;
      },

      async ModeloPut(body) {
        const resp = await API.put(`/editor_documento/${body.cod_editor_documento}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

        // CONTRATO
      async ContratoPut(body) {
        const resp = await API.put(`/contrato/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

      async ContratoDelete(body) {
          //só coloquei esse nome pra mudar a mensagem retornada pelo back por mais que estou usando put
          var resp = await API.put(`/contrato_delete/${body.cod_empreendvenda}`, JSON.stringify(body))
          const { data } = resp;
          return data;
      },

      async TransferenciaPost(body) {
        const resp = await API.post(`/transferencia`, JSON.stringify(body));
        const { data } = resp;
        return data;
      },

      async TransferenciaPut(body) {
        const resp = await API.put(`/transferencia/${body.cod_empreendvenda}`, JSON.stringify(body))
        const { data } = resp;
        return data;
      },

      async TransferenciaDelete(body) {
        const resp = await API.delete(`/transferencia/${body.cod_empreendvendatransf_chave}`)
        const { data } = resp;
        if (resp.status == 200) 
          return data.result;
        else
          return data;
      },
      
      
    },

    watch: {
      docDocumentos()           { this.atualiza_abas() },
      docContrato()             { this.atualiza_abas() },
      docTransferencia()        { this.atualiza_abas() },
      docRenogociacao()         { this.atualiza_abas() },
      docRescisao()             { this.atualiza_abas() },
      docAutorizacaoEscritura() { this.atualiza_abas() },
      docEscritura()            { this.atualiza_abas() },
      docMensagens()            { this.atualiza_abas() }
    },


})

export default vm_store_Proposta
